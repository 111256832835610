/**
 * Register resource for async load with require-bundle
 */
(function(){

    'use strict';

    // Bail if require bundle or server variables not loaded
    if ( ! requireBundle || ! rdnartsSettings ) return;

    var settings = rdnartsSettings,
        ver = rdnartsSettings.assetsVersion;

    // Helper Libraries
    requireBundle.register( 'animate-helper', [ settings.jsLibPath + 'animate-helper.min.js' ] );
    requireBundle.register( 'atomicjs', [ settings.jsLibPath + 'atomic.min.js' ] );
    requireBundle.register( 'hammerjs', [ settings.jsLibPath + 'hammer.min.js', settings.jsLibPath + 'hammerjs-init.min.js' ] );
    requireBundle.register( 'form-serialize', [ settings.jsLibPath + 'form-serialize.min.js' ] );
    requireBundle.register( 'button-states', [ settings.jsLibPath + 'button-states.min.js' ] );
    requireBundle.register( 'sticky-states', [ settings.jsLibPath + 'sticky-states.min.js', settings.jsPath + 'sticky-states-init' + ver + '.min.js', ] );
    requireBundle.register( 'multi-step-views', [ settings.jsLibPath + 'multi-step-views.min.js' ] );
    requireBundle.register( 'ga-event-tracking', [ settings.jsLibPath + 'ga-event-tracking.min.js' ] );
    requireBundle.register( 'scrollable-hint', [ settings.jsPath + 'scrollable-hint' + ver + '.min.js' ] );
    
    // International Phone Number Input
    requireBundle.register( 'intl-tel-input', [
        settings.jsLibPath + 'intlTelInput.min.js',
        settings.jsPath + 'intl-tel-input-init' + ver + '.min.js',
    ] );
    
    // FluidSlider
    requireBundle.register( 'fluid-slider', [
        settings.jsLibPath + 'fluid-slider.min.js',
        settings.cssPath + 'fluid-slider' + ver + '.min.css',
    ] );
    
    // Collapsible Block
    requireBundle.register( 'collapsible-block', [ settings.jsLibPath + 'collapsible-block.min.js', ] );

    // Flyout Block
    requireBundle.register( 'flyout-block', [
        settings.jsPath + 'flyout-block' + ver + '.min.js',
        settings.cssPath + 'flyout-block' + ver + '.min.css',
    ] );

})();
